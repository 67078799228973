/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';

.dropdown-filter-wapper {
    cursor: text;
    -webkit-user-select: text;
    user-select: all;

    .filter-column-wapper {
        display: grid;
        gap: 5px;
        justify-content: center;
        align-items: center;



        .filter-column-text {
            user-select: none;
            cursor: pointer;
        }

        .column-icon-button {
            border: none;
            outline: none;
            color: #FFFFFF;
            margin: 0;
            background-color: inherit;
            cursor: pointer;
            padding-top: 4px;
        }

        .column-sort-icon {
            border: none;
            outline: none;
            margin: 0;
            background-color: inherit;
            cursor: pointer;
            padding-top: 4px;
        }
    }

    .cols-3 {
        grid-template-columns: auto 20px auto;
    }

    .cols-2 {
        grid-template-columns: auto 1fr;
    }

    .filter-wapper {
        background: #FFFFFF;
        position: absolute;
        min-width: 220px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

        .filter-button-container {
            margin: 10px;
            display: flex;
            justify-content: center;
        }

    }


}
