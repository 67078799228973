/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';
.sidebar {
  width: 100%;
  height: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin-bottom: 20px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333; /* Dark grey text */
  position: relative; /* Establishes a positioning context for pseudo-elements */
  display: block;
  padding: 5px 5px 5px 30px;
  font-family: AdobeClean, sans-serif;
  font-size: px-to-rem(14px);
  font-weight: 600;
}

.sidebar ul li a:hover {
  background-color: #e7e7e7; /* Light grey background on hover */
  border-radius: 3px;
}

.sidebar ul li a.sidebar-external-link {
  color: #1473E6; /* Blue text without using !important */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar ul li a::before {
  content: ''; /* Required for the pseudo-element to be generated */
  position: absolute;
  left: 5px; /* Align with the start of the text */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center perfectly */
  width: 15px; /* Size of the custom disc */
  height: 15px; /* Size of the custom disc */
  background-color: #6D6D6D; /* Color of the custom disc */
  border-radius: 50%; /* Makes it circular */
}


.sidebar-group-heading {
  padding: 15px 15px; /* Padding inside the headings */
  cursor: pointer; /* Change mouse pointer on hover */
  font-weight: bold; /* Make the text bold */
  display: flex;
  align-items: center;
  background: 0;
  border: 0;
}

.sidebar-group-items {
  display: flex;
  background-color: #ffffff; /* Background of the collapsible items */
  flex-direction: column;
}

.sidebar-item {
  padding: 10px 15px;
  cursor: pointer;
  padding-left: 50px;
  transition: background-color 100ms ease-in-out;
  color: inherit;
  text-decoration: inherit;
}

.sidebar-item:hover {
  background-color: #e9e9e9; /* Item hover state */
}

.sidebar-group-items-icon{
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: flex;
}

.sidebar-group-toggle-icon{
  margin-left: auto;
  color: #747474;
  display: flex;
}
.sidebar-div-flex{
  display: flex;
}
.sidebar-div-flex-right{
  display: flex;
  margin-left: auto;
}

// ------

.sidebar-group {
  display: flex;
  place-items: stretch;
  flex-direction: column;
}

.active-style-Link {
  background-color: #e9e9e9;
}
