/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.account-details-page-header {
  margin: 0;
  align-self: center;
  color: #5258E3;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}

.account-details-top-bar-org-name {
  font-size: 24px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
