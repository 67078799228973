/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.Main-container{
    display: flex;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    gap: 50px;
   // padding-bottom: 40px;
}

.Main-container-Left{

    display: flex;
    width: 200px;
    height: 400px;
    background: white;
    top: 251px;
    position: sticky;
    top: 254px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 17px;
}

.Main-container-Right{

    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 800px;


}
