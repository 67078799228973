/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.user-page-card-title-count {
  font-size: 16px;
  border: 1px solid #d3d3d3;
  background-color: #7567EC;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.user-page-card-title {
  font-weight: 600;
  font-size: 16px;
  color: #505050;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.user-page-table-row-title {
  font-weight: 500;
  line-height: 1;
  font-size: 12px;
  color: #747474;
  text-transform: uppercase;
  letter-spacing: 0.72px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-page-table-row-value {
  font-weight: 600;
  font-size: 14px;
  color: #323232;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-page-table-row-link {
  font-weight: 600;
  font-size: 14px;
  color: #0265DC;
  margin: 0;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}

.user-page-table-row-link:hover {
  text-decoration: underline;
}

.user-page-parent-title {
  margin: 0;
  align-self: center;
  color: #5258E3;
  font-weight: 600;
  font-size: 22px;
}

.user-page-card {
  font-family: "adobe-clean";
  font-size: 14px;
  border: 1px solid #D5D5D5;
}

.divider-with-drop-shadow {
  background-color: #EAEAEA !important;
  box-shadow: 0px 3px 6px #00000029;
}
