/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.banner-container{
    margin: 0 auto;
    min-height: 50px;
    transition: all 0.3s ease;
    min-height: 50px;
    .banner-container-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      padding: 6px 17px;
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      color: white;
    }
  }
  .loading-text{
    color: #999;
    text-align: center;
    min-height: 50px;
  }
  .info-small{
    margin-top: -3px;
  }
