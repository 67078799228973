/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.aug-analysis-header {
  margin: 0;
  align-self: center;
  color: #5258E3;
  font-weight: 600;
  font-size: large;
}

.aug-analysis-logo {
  width: 32px;
  height: 32px;
}

.data-container-wrapper {
  box-shadow: 0 0 10px #00000029;
}
