/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@font-face {
    font-family: 'AdobeClean';
    src: url('../../assets/fonts/AdobeClean-Regular.woff') format('woff2');

    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AdobeCleanMedium';
    src: url('../../assets/fonts/AdobeClean-Medium.woff') format('woff2');

    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AdobeCleanRegular';
    src: url('../../assets/fonts/AdobeClean-Regular.woff') format('woff2');

    font-weight: normal;
    font-style: normal;
  }


.AdobeCleanBold{
    font-family: AdobeCleanBold !important;}
.AdobeCleanMedium{
      font-family: AdobeCleanMedium !important;}
.AdobeCleanRegular{
        font-family: AdobeCleanRegular !important;}
.AdobeClean{
          font-family: AdobeClean !important;}
