/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.account-details {
    margin-top: 20px;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
    padding-bottom: 14px;
    border-bottom: 1px solid #D3D3D3;
    display: flex;
    flex-direction: row;
    align-items: center;
    .account-details-orgid {
        margin-right: 33px;
        font-weight: bold;
        .orgId-color{
            font-weight: normal;
            margin-left: 10px;
        }
    }
}
