/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

// _mixins.scss
@mixin respond-to($breakpoint) {
    @if $breakpoint == mobile {
      @media (max-width: $breakpoint-mobile) { @content; }
    } @else if $breakpoint == tablet {
      @media (max-width: $breakpoint-tablet) { @content; }
    } @else if $breakpoint == desktop {
      @media (min-width: $breakpoint-desktop) { @content; }
    }
  }

  @mixin font-size-responsive($size-mobile, $size-tablet: $size-mobile, $size-desktop: $size-tablet) {
    font-size: $size-mobile;
    @include respond-to(tablet) { font-size: $size-tablet; }
    @include respond-to(desktop) { font-size: $size-desktop; }
  }

  // @function px-to-rem($px) {
  //   @return calc(#{$px} / var(--base-font-size) );
  // }

  @function px-to-rem($px) {
    @return $px / $base-font-size * 1rem;
  }
