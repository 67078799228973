/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';
.sticky-navbar {
  display: flex;
  background: transparent linear-gradient(268deg, #3271DD 0%, #A85BA9 30%, #D9418F 59%, #E62E6A 79%, #EB1002 100%) 0% 0% no-repeat padding-box;
  margin-left: 0px;
  padding: 0px 40px;
  min-height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.navbar-left-col-title {
  text-align: left;
  letter-spacing: 0.3px;
  font-size: px-to-rem(18px);
  margin-left: 15px;
  color: #FFFFFF;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 5%;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}
.navbar-left-col-Beta {
  text-align: left;
  letter-spacing: 0.3px;
  font-size: px-to-rem(12px);

  background-color: #FFFFFF;
  font-weight: 400;
  margin-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 20px;
  width: 30px;
  justify-content: center;
  line-height: normal;
  margin-top: 2%;
  align-self: center;
}
.navbar-adobeLogo-div{
  display: flex;
  cursor: pointer;
  user-select: none;
}

#navbar-user-name {
  color: #FFFFFF;
  font-size: .8rem;
  font-weight: 600;
  line-height: .8rem;
}

.navbar-adobeLogo {
  width: 25px;
  height: 25px;
}

.navbar-avatar {
  border-radius: 100%;
  width: 30px;
  aspect-ratio: 1 / 1;
}
