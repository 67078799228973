/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';

.feedback-form {
  opacity: 1;
  height: 87px;
  width: 73px;
  padding: 11px 0;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #EEEEEE;
  border-radius: 3px 0;
}

.feedback-form button[class*="is-hovered"],
.feedback-form button[aria-pressed="true"][aria-expanded="true"] {
  background: #E6EFFC 0 0 no-repeat padding-box !important;
}
.feedback-form button {
  margin: 0 18px 0 16px;
  height: 39px;
  width: 39px;
  border: #E9E9E9 1px solid !important;
  border-radius: 39px;
  background-color: #FFFFFF !important;
}

.feedback-form button[aria-label="Thumbs Up"] svg {
  color: #78C12F !important;
}

.feedback-form button[aria-label="Thumbs Down"] svg {
  color: #F29423 !important;
}

[aria-label=feedback-dialog] {
  padding: 0;
  margin: 0;
  width: 444px;
  --spectrum-dialog-padding-x: 57px;
  --spectrum-dialog-padding-y: 19px;
}

[aria-label=feedback-dialog] h2 svg {
  margin: 0 0 -5px -28px;
}

[aria-label=feedback-dialog] h2 span {
  margin: 0 0 0 4px;
  padding: 0;
  text-align: left;
  font: normal normal bold 18px/22px;
  letter-spacing: 0;
  color: #545454;
}
[aria-label=feedback-dialog] h2 span em {
  color: #7567EC;
  font: normal normal bold 18px/22px;
  font-style: normal;
}

[aria-label=feedback-dialog] textarea {
  width: 100% !important;
  font: italic normal normal 14px/17px;
  letter-spacing: 0;
  color: #8C8C8C;
  --spectrum-alias-placeholder-text-color: #8C8C8C;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  --spectrum-alias-placeholder-text-color-hover: #8C8C8C;
  --spectrum-alias-placeholder-text-color-active: #8C8C8C;
}

[aria-label=feedback-dialog] label {
  --spectrum-alias-text-color: #505050;
  color: #505050;
}

[aria-label=feedback-dialog] label {
  border-color: #959595 !important;
  --spectrum-checkbox-quiet-box-border-color-selected: #959595 !important;
  --spectrum-checkbox-emphasized-box-border-color: #959595 !important;
  --spectrum-checkbox-emphasized-box-border-color-selected: #959595 !important;
}

input[aria-label=minutes] {
    //padding: 3px 6px 5px 5px;
  --spectrum-alias-placeholder-text-color: #8C8C8C;
  --spectrum-alias-placeholder-text-color-hover: #8C8C8C;
  --spectrum-alias-placeholder-text-color-active: #8C8C8C;
}
.checkbox-saved label {
  padding: 0 3px 0 0;
}
.checkbox-saved [class*="spectrum-Textfield"] {
  margin: 0 6px 0 0;
}
.checkbox-saved [class*="spectrum-Textfield"] input {
  border: 1px solid #E6E6E6;
  border-radius: 5px;
}
.checkbox-saved [class*="spectrum-FocusRing"]:after {
  width: 35px;
}
.checkbox-saved input {
  width: 35px;
  font: italic normal normal 14px/17px;
  letter-spacing: 0;
  color: #8C8C8C;
  --spectrum-textfield-padding-x: 8px;
  --spectrum-alias-single-line-width: 35px;
}

svg[aria-label="Thumbs Up"] {
  color: #78C12F !important;
}

svg[aria-label="Thumbs Down"] {
  color: #F29423 !important;
}
