/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.recents-dialog{
  width: fit-content;
  background-color: white;
  border-radius: 4px;
  border: none !important;
  box-shadow: 0px 5px 8px #00000029;
  padding: 13px;

  &:focus {
    outline: none;
  }
}

.recents-dialog-heading {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
  line-height: 18px;
}

.recents-section-title {
  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
  color: #323232;
  letter-spacing: 0.7px;
}

.recents-link {
  font-size: 14px;
  line-height: 18px;
  color: #1373E6;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recents-link:hover {
  text-decoration: underline;
}

.recents-link.active {
  text-decoration: underline;
}
