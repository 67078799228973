/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.search_wrapper {
  height: calc(100vh - 200px);

  .search_container {
    height: 100%;
    width: auto;
    padding: 0 10vmin;
    gap: 1em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;


    .search_heading_wrapper {
      width: 100%;
      display: flex;
      flex-flow: column;
      padding-right: 5vmin;
      margin-left: 0;

      .search_heading {
        font: normal normal bold 22px/29px AdobeClean !important;
        color: #323232;
        text-align: start;
        margin: 0;
      }
      .search_description {
        font: normal normal normal 16px/24px AdobeClean !important;
        color: #505050;
        text-align: start;
        padding-top: 0.875rem;
        margin: 0;
      }
      .search_link {
        font: normal normal normal 16px AdobeClean !important;
        color: #0D66D0;
        text-align: start;
        padding-top: 0.875rem;
        margin: 0;
      }

    }
    .search_image_wrapper {
      margin-left: 0;

      .search_homepage-img {
        width: 439px;
        height: 440px;
      }
    }

    .searchbar_container {
      margin: 5% 10% 0;
    }
  }


  .cell_link {
    font: normal normal normal 14px/17px AdobeClean !important;
    letter-spacing: 0;
    color: #1473e6;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.data-container-wrapper {
  .search-table-container {
    width: 100%;
    margin-top: 41px;
  }

  .search-table-header {
    border: 1px solid #EAEAEA;
    box-shadow: 0 3px 3px #00000029;
  }

  .search-table-heading {
    font: normal normal bold 22px/29px AdobeClean;
    color: #000000;
    margin: 40px 0;
  }

  .search-table-wrapper {
    position: relative;
  }

  .search-table-loading-wrapper {
    position: absolute;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}

