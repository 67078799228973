/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.pagination-container {
    display: flex;
    list-style-type: none;
    user-select: none;

    .pagination-item-border {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #D3D3D3;
      text-align: left;
      font: normal normal normal 11px/12px AdobeClean;
      letter-spacing: 0.22px;
      text-transform: capitalize;
      opacity: 1;
    }

    .pagination-item-left-arrow {
      padding-top: 0px !important;
      padding-bottom: 5px;
      padding-right: 2px;
    }

    .pagination-item-right-arrow {
      padding-top: 2px !important;
      padding-bottom: 0px;
      padding-right: 2px;

    }

    .pagination-item-text-blue {
      color: #1473E6 !important;
    }

    .pagination-item-text-grey {
      color: rgba(0, 0, 0, 0.87);
    }

    .pagination-item {
      height: 24px;
      text-align: center;
      margin: auto 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 24px;
      padding-top: 4px;


      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background: #1473E6 0% 0% no-repeat padding-box;
        font: normal normal normal 11px/12px AdobeClean;
        color: #FFF;
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }

        &.left {
          transform: rotate(-135deg) translate(-50%);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
