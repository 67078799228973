/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.filter-container {
    display: block;

    .filter-label {
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
    }
}

.marginRight {
    margin-right: 10px;
}

.custom-combo-box .spectrum-Dropdown-icon {
    border: none;
    border-right: none !important;
    background: transparent;
    box-shadow: none;
}

.filter-list-container {
    display: inline-block;
    margin-top: 11px;
    margin-bottom: 31px;

    .selected-filter-container {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        border: 1px solid #1473E6;
        border-radius: 4px;
        margin-left: 0px;
        margin-right: 14px;
        padding: 0 8px;
         &:last-of-type{
            margin-right: 0px;
         }
        .filter-checkmark {
            background-color: #ffffff;
            border-radius: 50%;
            border: none;
            color: #268E6C;
            width: 16px;
        }

        .selected-filter {
            margin-left: 10px;
            margin-right: 17px;
            color: #6D6D6D;
            font-weight: normal;
        }
    }

}
