/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';

.ticket-info {
  box-shadow: 3px 3px 8px #0000001F;
  display: flex;
  overflow: visible;
}

.ticket-info > * + * {
  border-left: 1px solid rgb(0 0 0 / 10%);
}

.ticket-info__header {
  display: flex;
}
.ticket-info__ContextualHelp {
  cursor: pointer;
}
.ticket-info__data {
  color: #323232;
  font-size: px-to-rem(12px);
  font-weight: 700;
}
.ticket-info__data--long {
  color: #505050;
  font-size: px-to-rem(12px);
  white-space: pre-line;

}

.ticket-info__section-title {
  color: #747474;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.72px;
  word-wrap: break-word;
  flex: 1;
}

.ticket-info__section-subtitle {
  color: #747474;
  font-size: px-to-rem(10px);
  font-weight: 500;
  text-transform: uppercase;
  word-wrap: break-word;
  flex: 1;
}

.banner__dsat__summary {
  font-size: px-to-rem(16px);
  font-weight: bold;
}
.banner__dsat__summary strong {
  font-weight: bolder;
}
.banner__dsat p {
  margin: .5em 0 0;
}

.indicator__label.indicator__label.indicator__label svg {
  fill: inherit;
}

.watchlist-switch-label {
  font-weight: 500;
  font-size:10px;
}
