/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */




.cfs-system-prompt-label {
    box-sizing: border-box;
    padding: var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65));
    font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
    font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
    line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    text-align: start;
    cursor: default;
    flex: none;
    display: flex;
  }
