/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.NotFound_wrapperr{
    padding-left: 15px;
    background-image: url('../../assets/img/AdobeStock_350021263.jpeg'); // Adjust the path as needed
  background-size: cover; // Cover the entire area of the element
  background-position: center; // Center the background image
  height: calc(100vh - 81px)
}

.FeatureCard_wrapper{
    display: flex;


}
.FeatureCard{
    margin: 1rem;
    padding: 1rem;

    display: flex;
    flex-basis: 1;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6E6;
border-radius: 4px;
}
.FeatureCard_title{
    font: normal normal bold 16px / 20px Adobe Clean;
    margin-top: 30px;
    margin-bottom: 6px;
}
.FeatureCard_description{
    font: normal normal normal 14px/20px Adobe Clean;
    margin-top: 22px;

}
.FeatureCard_button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 22px;
    font: normal normal bold 14px/17px Adobe Clean;
}
.FeatureCard_button_text{
font: normal normal bold 14px/17px Adobe Clean;
height: 32px;
}
.FeatureCardLego{


}
