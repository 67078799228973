/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.risk-level-icon {
  width: 16px;
  height: 16px;
  align-self: center;
}

.finding-documentation-list ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  display: inline-flex;
}

.finding-documentation-list ul li {
  display: none;
}

.finding-documentation-list ul li:first-child {
  display: block;
}
