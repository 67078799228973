/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.prompt-selector__lifecycle {
    display: flex;
    justify-content: space-around;
    gap: 1em;
    flex-direction: row;
    padding: 0 2em;
    list-style-type: none;

    & > * {
        width: 100%;
        max-width: 240px;
    }
}
.prompt-selector__progress-bar {
    background: red;
    background-image: linear-gradient(to right, red, magenta, cyan);
    height: .25em;
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: space-around;
}
.prompt-selector__progress-node {
    display: block;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    border: .25em solid blueviolet;
    background: white;
}

sup {
	font-size: 70%;
	position: relative;
	bottom: .5em;
}
