/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import './base/variables';
@import './base/mixins';
.Footer-adobeLogo{
    width: 18px;
height: 18px;
margin-right: 12px;
}

.footer{
    position: sticky;
    bottom: 0px;
    height: 30px;
    color: #505050;
    z-index: 2;
    display: flex;
    background-color: rgb(234, 234, 234);
    align-content: center;
    grid-area: footer;
    align-content: center;
    align-items: center;
    padding-left: 40px;
    font-size: px-to-rem(12px);

    font-family: AdobeClean !important;
}

.footer_left_section{
    display: flex;
}

.footer_left_Copyright{
    padding-top: "3px" ;
    font-size: px-to-rem(12px) !important;
}
.footer_right_section{
    display: flex;
    margin-left: auto;
}

.footer_right_section_link{
    color: #1473E6;
    margin-right: 28px;
    padding-top: '3px'
}
