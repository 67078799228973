/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import '../base/variables';
@import '../base/mixins';


.oasis-dialog {
  min-width: 80vw;
  height: 40vw;
  border-radius: 0;
  background-color: white;
}

.oasis-dialog :any-link, .oasis-dialog button {
  cursor: pointer;
}

.oasis-dialog .oasis-dialog__ribbon {
  background-color: var(--spectrum-alias-background-color-red-800, var(--spectrum-red-800, var(--spectrum-semantic-red-800-color-background)));
  display: flex;
  grid-column: 1 / -1;
  grid-row: 2 / 2;
  padding: 0 1em;
  flex-direction: row;
  place-items: center;
  color: white;
  justify-content: space-between;
  z-index: 1;
}

.oasis-dialog[data-modal-type="fullscreen"] .oasis-dialog__ribbon {
  grid-row: 1 / 1;
}

.oasis-dialog__ribbon_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5em;
}

.oasis_dialog__ribbon_title {
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
}

.oasis-dialog__ribbon-button {
  border: 0;
}

.oasis-dialog__inline-button {
  border: 0;
  color: inherit;
  vertical-align: middle;
}

.modal-section-title {
  display: block;
  font: normal normal bold 14px/18px Adobe Clean;
  font-size: 1em;
  letter-spacing: 0.84px;
  color: #323232;
  text-transform: uppercase;
  opacity: 1;
}

.oasis_dialog__heading {
  font-size: px-to-rem(18px);
  font-weight: bold;
  letter-spacing: 0.84px;
  color: #000000;
}

.modal-adobeLogo {
  width: 15px;
  height: 15px;
}

details {
  width: auto;
  overflow: hidden;
}

details summary {
  cursor: pointer;
  display: flex;
  align-items: center;
}

details > summary {
  width: fit-content;
  border: none;
}

details[close] {
  height: 0;
}

details > div {
  padding: 5px 0;
  margin: 0;
}

.summary-arrow {
  transition: transform 0.3s ease;
  color: #747474;
}

details[open] .summary-arrow {
  transform: rotate(90deg);
}

.markdown-container {
  font-size: 1.05em;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  font-size: inherit;
}

.markdown-container > *:first-child {
  margin-top: 0;
}

.markdown-container > *:last-child {
  margin-bottom: 0;
}

