/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

// Breakpoints
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

// Typography
$font-primary: 'Adobe Clean', sans-serif;
$base-font-size: 16px;
$font-size-mobile: 16px;

:root {
    --base-font-size: 16px; // Default for mobile devices
    // iPads & Tablets
    // @media (min-width: 768px) {
    //   --base-font-size: 14px; // Non-Retina iPads (Mini, Air in portrait)
    // }
    @media (min-width: 1024px) {
      --base-font-size: 16px; // Retina iPads, smaller laptops
    }
    // Small Laptops
    @media (min-width: 1280px) {
      --base-font-size: 16px; // Common for smaller laptops
    }
    @media (min-width: 1366px) {
      --base-font-size: 18px; // Common minimum laptop resolution
    }
    // Standard Laptop Sizes
    @media (min-width: 1440px) {
      --base-font-size: 18px; // MacBook Air 13", etc.
    }
    @media (min-width: 1536px) {
      --base-font-size: 18px; // MacBook Pro 15" Retina
    }
    @media (min-width: 1600px) {
      --base-font-size: 18px; // Some higher-end laptops
    }
    @media (min-width: 1920px) {
      --base-font-size: 20px; // Full HD laptops and displays
    }
    // High Resolution Laptops and Displays
    @media (min-width: 2560px) {
      --base-font-size: 22px; // Some QHD laptops
    }
    @media (min-width: 2880px) {
      --base-font-size: 22px; // MacBook Pro 15" Retina, some 4K displays in scaled mode
    }
    @media (min-width: 3840px) {
      --base-font-size: 24px; // 4K laptops and displays
    }
  }


