/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

.searchbar_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  div:first-child {
    width: 100%;
    box-shadow: 0px 0px 0.75rem #00000029;
    border-radius: 6px;
    height: 72px;

    input {
      width: 100%;
      height: 72px;
      padding-left: 3.75rem;
      padding-right: 1.875rem;
      font: normal normal normal 20px AdobeClean !important;
      color: #464646;
      padding-top: 0.625rem;
    }

    input::placeholder {
      font: normal normal normal 20px AdobeClean !important;
      color: #464646;
    }

    svg {
      top: 28px;
      left: 26px !important;
    }

    div > div:last-child {
      top: 20px;
      right: 15px !important;
    }
  }
}
